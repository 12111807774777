<template>
  <div class="vasion-calc-height">
    <EmailTemplate @isDirty="updateIsDirty" />
    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>
  </div>
</template>

<script>

import EmailTemplate from '@/views/admin/EmailTemplate.vue';

export default {
  name: 'EmailTemplateContainer',
  components: {
    EmailTemplate,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  // This protects the route from being changed if you click on a different email template in the left-hand menu.
  // It appears it is called twice when navigating away, so the showLeaveDialog value is checked.
  beforeRouteUpdate(to, from, next) {
    if (this.isDirty && (!this.routeTo || this.showLeaveDialog)) {
      this.routeTo = to
      this.showLeaveDialog = true
      next(false)
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      isDirty: false,
      showLeaveDialog: false,
    }
  },
  methods: {
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    updateIsDirty(val) { this.isDirty = val },
  },
}
</script>