<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ templateName ? templateName : 'New Email Template' }}</h1>
      <div class="btns-container">
        <VasionButton
          id="save-template-button"
          classProp="secondary"
          @vasionButtonClicked="cancelClick()"
        >
          Cancel
        </VasionButton>
        <VasionButton
          id="save-template-button"
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="saveTemplate()"
        >
          Save
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="md-layout design-div">
        <div class="document-tags-div">
          <ul class="vasion-ul">
            <li id="document-notes" @click="addToBodyOnCursor('<<Document Notes>>')">
              <span class="li-background"><span class="icon-bar">
                <VasionDocumentNoteIcon /></span>
                Document Notes</span>
            </li>
            <li id="workflow-notes" @click="addToBodyOnCursor('<<Workflow Notes>>')">
              <span class="li-background"><span class="icon-bar">
                <VasionWorkflowNoteIcon /></span>
                Workflow Comments</span>
            </li>
            <li id="last-wf-comment" @click="addToBodyOnCursor('<<Last WF Comment>>')">
              <span class="li-background"><span class="icon-bar">
                <VasionWorkflowNoteIcon /></span>
                Last WF Comment</span>
            </li>
            <hr class="separator">
            <li id="document-name" @click="addToBodyOnCursor('<<Document Display Name>>')">
              <span class="li-background"><span class="icon-bar">
                <VasionDocumentNameIcon /></span>
                Document Name</span>
            </li>
            <li id="created-date" @click="addToBodyOnCursor('<<Document Creation Date>>')">
              <span class="li-background"><span class="icon-bar">
                <VasionCreatedDateIcon /></span>
                Created Date</span>
            </li>
            <li id="document-tasks" @click="addToBodyOnCursor('<<Document Tasks>>')">
              <span class="li-background"><span class="icon-bar">
                <VasionDocumentTasksIcon /></span>
                Document Tasks</span>
            </li>
            <li id="document-links" @click="addToBodyOnCursor('<<Document Link>>')">
              <span class="li-background"><span class="icon-bar">
                <VasionLinkIcon /></span>
                Document Link</span>
            </li>
            <li id="document-fields" @click="addToBodyOnCursor('<<Document Fields>>')">
              <span class="li-background"><span class="icon-bar">
                <VasionInfoOutline /></span>
                Document Fields</span>
            </li>
            <hr class="separator">
            <li id="begin-repeat" @click="addToBodyOnCursor('<<Start Repeat Document>>')">
              <span class="li-background"><span class="icon-bar">
                <VasionBeginRepeatIcon /></span>
                Begin Repeat</span>
            </li>
            <li id="end-repeat" @click="addToBodyOnCursor('<<End Repeat Document>>')">
              <span class="li-background"><span class="icon-bar">
                <VasionEndRepeatIcon /></span>
                End Repeat</span>
            </li>
          </ul>
        </div>
        <div class="divider-div" />
        <div class="md-layout-item body-div">
          <div class="body-text-area-div">
            <textarea
              id="body-text-area"
              ref="opts"
              v-model="body"
              class="vasion-textarea vasion-white-background text-area-height"
              @click="updateBodySelectionStart"
              @keyup="updateBodySelectionStart"
              @change="isDirty = true"
            />
          </div>
        </div>
        <div class="divider-div" />
        <div class="md-layout-item details-div">
          <div class="vasion-page-subheader detail-attribute-div details-label">
            <label>Template Details</label>
          </div>
          <div class="detail-attribute-div">
            <VasionInput
              id="template-name"
              v-model="templateName"
              class="input-style"
              inputType="top-white"
              width="100%"
              title="TEMPLATE NAME"
              name="template-name"
              @input="isDirty = true"
            />
          </div>
          <div class="detail-attribute-div">
            <label class="vasion-input-label-top">EMAIL SUBJECT</label>
            <textarea
              id="subject-text-area"
              ref="subject"
              v-model="subject"
              class="vasion-textarea"
              @click="updateSubjectSelectionStart"
              @keyup="updateSubjectSelectionStart"
              @change="isDirty = true"
            />
          </div>
          <div class="detail-attribute-div">
            <VasionDropList
              v-slot="slotItem"
              v-model="selectedForm"
              :dataArray="indexForms"
              :title="`${$formsLabel.toUpperCase()}`"
              inputType="plain-list"
              valueName="value"
              displayName="name"
              width="100%"
              class="attribute-droplist"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div class="detail-attribute-div">
            <VasionDropList
              v-slot="slotItem"
              v-model="selectedField"
              :dataArray="indexFieldsForForm"
              title="FIELD"
              inputType="plain-list"
              valueName="value"
              displayName="name"
              width="100%"
              class="attribute-droplist"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div class="detail-attribute-div md-layout">
            <VasionButton id="add-to-body-button" :classProp="'secondary'" @vasionButtonClicked="addToBodyOnCursor(getSelectedFieldNameTag())">
              Add to Body
            </VasionButton>
            <VasionButton id="add-to-subject-button" :classProp="'secondary'" @vasionButtonClicked="addToSubjectOnCursor(getSelectedFieldNameTag())">
              Add to Subject
            </VasionButton>
          </div>
          <div>
            <div class="vasion-page-subheader detail-attribute-div details-label">
              <label>Document Link Settings</label>
            </div>
            <div class="detail-attribute-div">
              <VasionDropList
                v-slot="slotItem"
                v-model="selectedUser"
                :dataArray="users"
                title="OPEN AS"
                inputType="plain-list-search"
                valueName="value"
                displayName="name"
                width="100%"
                class="attribute-droplist"
                :openAbove="true"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>
            <div class="detail-attribute-div">
              <VasionInput
                id="expiration-hours"
                v-model="expHours"
                class="input-style"
                inputType="top-white"
                type="number"
                min="0"
                oninput="this.value = Math.abs(this.value)"
                width="100%"
                title="EXPIRATION HOURS"
                name="expiration-hours"
                @input="isDirty = true"
              />
            </div>
            <div class="detail-attribute-div">
              <VasionInput
                id="max-views"
                v-model="maxViews"
                class="input-style"
                inputType="top-white"
                type="number"
                min="0"
                oninput="this.value = Math.abs(this.value)"
                width="100%"
                title="MAX VIEWS"
                name="max-views"
                @input="isDirty = true"
              />
            </div>
            <div class="detail-attribute-div">
              <VasionInput
                id="max-downloads"
                v-model="maxDownloads"
                class="input-style"
                inputType="top-white"
                type="number"
                min="0"
                oninput="this.value = Math.abs(this.value)"
                width="100%"
                title="MAX DOWNLOADS"
                name="max-downloads"
                @input="isDirty = true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <VasionSnackbar
      id="email-template-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>
<script>

export default {
  name: 'EmailTemplate',
   props: {
    emitClose: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: function () {
    return {
      body: '',
      expHours: 0,
      indexForms: [],
      isDirty: false,
      maxDownloads: 0,
      maxViews: 0,
      position: 0,
      selectedUser: '',
      selectedField: '',
      selectedForm: '',
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      subject: '',
      templateID: 0,
      templateName: '',
      userID: 0,
    }
  },
  computed: {
    indexFieldsForForm() { return this.$store.state.common.indexFields ? this.$store.state.common.indexFields : [] },
    selectedEmailTemplate() { return this.$store.state.admin.selectedEmailTemplate },
    users() {
      // the .slice is important below - otherwise we're adding [Force Login] directly to the store
      const fieldArray = this.$store.state.common.users ? this.$store.state.common.users.slice() : []
      if (fieldArray.findIndex(x => x.name === '[Force Login]') === -1) {
        fieldArray.unshift({
        name: '[Force Login]',
        value: 0,
      })
      }
      return fieldArray
    },
  },
  watch: {
    indexFieldsForForm: function () {
      if (this.indexFieldsForForm && this.indexFieldsForForm.length > 0) {
        // eslint-disable-next-line
        this.selectedField = this.indexFieldsForForm[0]
      } else {
        this.selectedField = ''
      }
    },
    isDirty() { this.$emit('isDirty', this.isDirty) },
    selectedEmailTemplate() { this.setTemplateValues() },
    selectedForm: function () {
      const formID = this.selectedForm && this.selectedForm.value ? this.selectedForm.value : 0
      this.$store.dispatch('common/getIndexFieldsForForm', formID)
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('admin/setEmailSelectedEmailTemplate', this.$route.params.templateId)
  },
  created: async function () {
    this.$store.dispatch('common/getIndexFieldsForForm', 0)
    this.$store.dispatch('common/getUsers')

    this.setTemplateValues()

    this.indexForms = await this.$store.dispatch('attributeForm/getForms')

    if (this.indexForms && this.indexForms.length > 0) {
      // eslint-disable-next-line
      this.selectedForm = this.indexForms[0]
    }
    if (this.users && this.users.length > 0) {
      const index = this.arrayIndex(this.userID)
      // eslint-disable-next-line
      this.selectedUser = this.users[index]
    }
    if (!this.selectedUser) {
      // eslint-disable-next-line
      this.selectedUser = this.users[0]
    }
  },
  methods: {
    addToBodyOnCursor(message) {
      this.isDirty = true
      const txtArea = this.$refs.opts

      if (this.position === 0) {
        this.position = txtArea.selectionStart
      }

      this.body = [this.body.slice(0, this.position), message, this.body.slice(this.position)].join('');
      this.position += message.length
      setTimeout(() => {
        txtArea.focus()
        txtArea.setSelectionRange(this.position, this.position)
      }, 0)
    },
    addToSubjectOnCursor(message) {
      this.isDirty = true
      const txtArea = this.$refs.subject

      if (this.position === 0) {
        this.position = txtArea.selectionStart
      }

      this.subject = [this.subject.slice(0, this.position), message, this.subject.slice(this.position)].join('');
      this.position += message.length
      setTimeout(() => {
        txtArea.focus()
        txtArea.setSelectionRange(this.position, this.position)
      }, 0)
    },
    arrayIndex(fieldValue) { return this.users.findIndex(x => x.value === fieldValue) },
    cancelClick() { this.navigateBack() },
    clearFields() {
      // This method may not be used explicitly inside the component, but a parent component may be using it
      this.body = ''
      this.subject = ''
      this.templateName = ''
      this.templateID = 0
    },
    getSelectedFieldNameTag() {
      const fieldName = this.selectedField?.value ? this.selectedField.value : ''

      if (fieldName === '') {
        return fieldName
      }

      return `<${fieldName}> `
    },
    navigateBack() {
      if (this.emitClose === true) {
        this.$emit('editingComplete')
      } else {
        this.$router.push({ name: 'AllEmailTemplates' })
      }
    },
    async saveTemplate() {
      const templateNameWithNoSpaces = this.templateName.replace(/\s/g, '')
      if (this.templateName === '' || templateNameWithNoSpaces === '') {
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = 'Please enter a valid Template Name.'
        this.snackbarImage = false
        this.showSnackbarBool = true
        return
      }
      // const value = this.arrayIndex(this.selectedUser.value)
      this.userID = this.selectedUser.value

      const savePayload = {
        templateID: this.templateID,
        templateName: this.templateName,
        subject: this.subject,
        body: this.body,
        docLinkExpirationHours: this.expHours,
        docLinkMaxViews: this.maxViews,
        docLinkMaxDownloads: this.maxDownloads,
        docLinkUserID: this.userID,
      }

      const saveResult = await this.$store.dispatch('admin/saveEmailTemplate', savePayload)
      if (!saveResult || saveResult.templateID === undefined) {
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = saveResult.Value
        this.snackbarImage = false
        this.showSnackbarBool = true
        return
      }
      this.$emit('isDirty', false)

      this.snackbarTitle = 'Success!'
      this.snackbarSubTitle = `Email Template successfully ${this.templateID == 0 ? 'created' : 'saved'}.`
      this.snackbarImage = true
      this.showSnackbarBool = true

      setTimeout(() => {
        this.navigateBack()
      }, 1500)
    },
    setTemplateValues() {
      if (this.selectedEmailTemplate && this.selectedEmailTemplate !== undefined) {
        this.templateID = this.selectedEmailTemplate.templateID
        this.templateName = this.selectedEmailTemplate.templateName
        this.subject = this.selectedEmailTemplate.subject
        this.body = this.selectedEmailTemplate.body
        this.maxViews = this.selectedEmailTemplate.docLinkMaxViews
        this.expHours = this.selectedEmailTemplate.docLinkExpirationHours
        this.maxDownloads = this.selectedEmailTemplate.docLinkMaxDownloads
        this.userID = this.selectedEmailTemplate.docLinkUserID
        if (this.$route.params.copy) {
          this.templateID = 0
          this.templateName = `Copy of ${this.templateName}` 
        }
      }
    },
    updateBodySelectionStart() { this.position = this.$refs.opts.selectionStart },
    updateSubjectSelectionStart() { this.position = this.$refs.subject.selectionStart },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .detail-attribute-div {
    margin-bottom: 16px;
  }

  .details-label {
    margin-bottom: 16px;
  }

  #body-text-area {
    width: 100%;
    height: 600px;
  }

  #subject-text-area {
    width: 100%;
    height: 70px;
  }

  .body-text-area-div {
    padding: 15px;
    height: calc(100vh - 222px);
  }

  .text-area-height {
    height: 100% !important;
  }

  .design-div {
    -webkit-flex: 1; /* Chrome */
    flex: 1; /* NEW, */
    overflow: auto;
    background-color: $white;
  }

  .bottom-separator-div {
    border-bottom: solid 1px $divider-light-grey;
    padding-bottom: 5px;
  }

  .shift-right {
    margin-right: -8px;
  }

  .title-div {
    margin-bottom: 10px;
  }

  .document-tags-div {
    width: 256px;
    height: 100%;
  }
  .divider-div {
    width: 1px;
    background-color: $divider-light-grey;
  }
  .details-div {
    width: 340px;
    max-width: 340px;
    padding: 16px;
    padding-bottom: 0px;
    overflow-y: scroll;
    height: calc(100vh - 222px);
  }
  .body-div {
    background-color: $grey-75;
  }
  .icon-bar {
    margin: 12px 16px;
  }
  .li-background {
    width: 278px;
    height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: black;
    padding: 7px 0 0 0px;
    margin: 0 8px 1px 8px;
    fill: $primary;
    cursor: pointer;
    @include Subtitle;
  }
  .separator {
    border-color: #e4e5e6;
    border-width: .5px;
  }
</style>
